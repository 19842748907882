/* line 10, scss/70-modules/translation/_module.scss */
.m-translation {
	z-index: 3;
	text-align: left;
	width: auto;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	max-width: 100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-translation {
	text-align: right;
}

/* line 19, scss/70-modules/translation/_module.scss */
.paw-sub-header .m-translation {
	z-index: 0;
}

/* line 24, scss/70-modules/translation/_module.scss */
.translation__item {
	margin: 0 0 0 10px;
	width: auto;
	max-width: 100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .translation__item {
	margin: 0 10px 0 0;
}

/* line 29, scss/70-modules/translation/_module.scss */
.translation__item .selectboxit-text {
	text-indent: 5px;
	color: rgb(var(--color-global-secondary));
	text-transform: uppercase;
	font-size: 1em;
}

/* line 43, scss/70-modules/translation/_module.scss */
.translation__item .selectboxit-enabled:hover .selectboxit-text {
	color: rgb(var(--color-global-positive));
}

/* line 47, scss/70-modules/translation/_module.scss */
.translation__item .selectboxit-btn {
	background-color: transparent;
	border-width: 0;
}

/* line 51, scss/70-modules/translation/_module.scss */
.translation__item .selectboxit-btn.selectboxit-enabled:hover {
	background-color: rgb(var(--color-global-secondary));
	color: rgb(var(--color-global-positive));
}

/* line 57, scss/70-modules/translation/_module.scss */
.translation__item .selectboxit-list {
	border-color: rgb(var(--color-global-border));
}

/* line 65, scss/70-modules/translation/_module.scss */
.translation__item .selectboxit-focus > .selectboxit-option-anchor {
	color: rgb(var(--color-global-positive));
	background-color: rgb(var(--color-global-secondary));
}

@media only screen and (max-width: 59.999em) {
	/* line 24, scss/70-modules/translation/_module.scss */
	.translation__item {
		margin: 0;
		width: 30px;
		height: 30px;
		min-height: 30px;
	}
	/* line 78, scss/70-modules/translation/_module.scss */
	.translation__item .selectboxit {
		border-radius: 100%;
	}
	/* line 82, scss/70-modules/translation/_module.scss */
	.translation__item .selectboxit-btn {
		padding-right: 0;
		border-width: 1px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .translation__item .selectboxit-btn {
		padding-right: initial;
		padding-left: 0;
	}
	/* line 90, scss/70-modules/translation/_module.scss */
	.translation__item .selectboxit-text {
		text-indent: 30px;
		text-overflow: initial;
	}
	/* line 94, scss/70-modules/translation/_module.scss */
	.translation__item .selectboxit-text .c-language-flag {
		left: 6px;
		position: absolute;
		top: 8px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .translation__item .selectboxit-text .c-language-flag {
		left: auto;
		right: 6px;
	}
	/* line 101, scss/70-modules/translation/_module.scss */
	.translation__item .selectboxit-arrow-container {
		display: none;
	}
	/* line 105, scss/70-modules/translation/_module.scss */
	.translation__item .selectboxit-options {
		right: 0;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .translation__item .selectboxit-options {
		right: auto;
		left: 0;
	}
	/* line 109, scss/70-modules/translation/_module.scss */
	.translation__item .selectboxit-option-icon-container {
		text-indent: 0;
	}
}

@media only screen and (max-width: 59.999em) {
	/* line 121, scss/70-modules/translation/_module.scss */
	.translation__item--no-flag .selectboxit-text {
		text-align: center;
		text-transform: uppercase;
		font-size: 11px;
		font-weight: bold;
		width: 100%;
		position: relative;
		color: rgb(var(--color-global-secondary));
	}
	/* line 131, scss/70-modules/translation/_module.scss */
	.translation__item--no-flag .selectboxit-text:hover {
		color: rgb(var(--color-global-positive));
	}
}

/* line 136, scss/70-modules/translation/_module.scss */
.translation__item--no-flag .selectboxit-text .language-iso {
	left: 0;
	position: absolute;
	top: 0;
	text-indent: 0;
	line-height: 28px;
	width: 100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .translation__item--no-flag .selectboxit-text .language-iso {
	left: auto;
	right: 0;
}

@media only screen and (max-width: 59.999em) {
	/* line 136, scss/70-modules/translation/_module.scss */
	.translation__item--no-flag .selectboxit-text .language-iso {
		display: block;
	}
}

/* line 150, scss/70-modules/translation/_module.scss */
.translation__item--no-flag .language-iso {
	display: none;
}

/* line 156, scss/70-modules/translation/_module.scss */
.translation__mobile-text {
	display: none;
	margin-top: -20px;
	text-transform: uppercase;
}

@media only screen and (max-width: 59.999em) {
	/* line 156, scss/70-modules/translation/_module.scss */
	.translation__mobile-text {
		display: inline-block;
	}
}

/* line 167, scss/70-modules/translation/_module.scss */
.m-footer-lang {
	font-size: 0.92em;
	line-height: 1.4em;
	font-weight: 500;
	margin-bottom: 20px;
}

@media only screen and (max-width: 47.999em) {
	/* line 167, scss/70-modules/translation/_module.scss */
	.m-footer-lang {
		font-size: 1.0718em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 167, scss/70-modules/translation/_module.scss */
	.m-footer-lang {
		font-size: 0.92em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 167, scss/70-modules/translation/_module.scss */
	.m-footer-lang {
		font-size: 0.92em;
	}
}

/* line 174, scss/70-modules/translation/_module.scss */
.footer-lang__list {
	font-family: var(--font-family-text);
	font-display: swap;
}

/* line 179, scss/70-modules/translation/_module.scss */
.footer-lang__item {
	margin-bottom: 10px;
}

/* line 182, scss/70-modules/translation/_module.scss */
.footer-lang__item:after {
	border-left: 1px solid rgb(var(--color-global-border));
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	width: 0;
	height: 9px;
	content: "";
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .footer-lang__item:after {
	border-left: 0;
	border-right: 1px solid rgb(var(--color-global-border));
}

/* line 192, scss/70-modules/translation/_module.scss */
.footer-lang__item:last-child:after {
	display: none;
}

/* line 198, scss/70-modules/translation/_module.scss */
.footer-lang__link {
	padding: 0 10px;
	color: rgb(var(--color-global-secondary));
}

/* line 203, scss/70-modules/translation/_module.scss */
.footer-lang__link:hover {
	color: rgb(var(--color-global-secondary));
}

/* line 208, scss/70-modules/translation/_module.scss */
.translation__list-link {
	display: block;
	color: rgb(var(--color-global-secondary));
	padding: 5px 10px;
	position: relative;
}

/* line 214, scss/70-modules/translation/_module.scss */
.translation__list-link:hover, .translation__list-link.is-selected {
	background-color: rgb(var(--color-global-title));
}

/* line 223, scss/70-modules/translation/_module.scss */
.translation__list-link:hover {
	color: rgb(var(--color-global-secondary));
}

/* line 245, scss/70-modules/translation/_module.scss */
.translation__list-icon {
	font-size: 0.834em;
	top: 50%;
	transform: translate(-0, -50%);
	right: 10px;
	color: rgb(var(--color-global-secondary));
	position: absolute;
}

@media only screen and (max-width: 47.999em) {
	/* line 245, scss/70-modules/translation/_module.scss */
	.translation__list-icon {
		font-size: 0.97161em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 245, scss/70-modules/translation/_module.scss */
	.translation__list-icon {
		font-size: 0.834em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 245, scss/70-modules/translation/_module.scss */
	.translation__list-icon {
		font-size: 0.834em;
	}
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .translation__list-icon {
	right: auto;
	left: 10px;
}

@media only screen and (max-width: 47.999em) {
	/* line 17, scss/80-themes/AffinityDefault/70-modules/translation/module.scss */
	.m-translation {
		padding-right: 20px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .m-translation {
		padding-right: initial;
		padding-left: 20px;
	}
}

/* line 22, scss/80-themes/AffinityDefault/70-modules/translation/module.scss */
.translation__item {
	margin: 0 0 0 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .translation__item {
	margin: 0 0 0 0;
}

/*# sourceMappingURL=../../../../../true */